import { useToast } from "vue-toastification";
import { ref, onMounted, computed, nextTick } from "vue";
const toast = useToast();

// Função para contar/limitar os caracteres do textarea
function textarea_limite() {
  const teclasPermitidas = [8, 9, 37, 38, 39, 40, 46, 17, 67, 86, 65];

  const inputs = document.querySelectorAll("textarea");
  inputs.forEach(input => {
    // Verificar se o elemento possui a classe "consulta"
    if (input.closest(".consulta")) {
      input.setAttribute("readonly", true);
      return; // Ignorar elementos com a classe "consulta"
    }
    // Verificar se o elemento possui a classe "contador"
    if (!input.closest(".contador")) {
      return; // Ignorar elementos sem a classe "contador"
    }

    const limite = parseInt(input.dataset.limite);

    input.setAttribute("maxlength", limite);

    const formGroup = input.closest(".contador");

    // Adiciona um ouvinte de evento para criar o contador quando o campo recebe o foco
    input.addEventListener("focus", () => {
      const labelSpan = document.createElement("span");
      labelSpan.classList.add("limite"); // Adiciona a classe "limite" ao span
      labelSpan.style.float = "right";

      // Adiciona o contador como irmão do input
      input.parentNode.insertBefore(labelSpan, input.nextSibling);

      function updateCharCount() {
        const qtd = input.value.length;
        labelSpan.innerHTML = `${qtd} / ${limite}`;
      }

      updateCharCount(); // Atualiza o contador quando o campo recebe o foco

      input.addEventListener("input", () => {
        updateCharCount();
      });

      // Adiciona um ouvinte de evento para ocultar o contador quando o campo perde o foco
      input.addEventListener("blur", () => {
        labelSpan.remove();
      });

      input.addEventListener("paste", e => {
        const qtd = e.clipboardData.getData("text/plain").length;
        if (qtd > limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!\nSeu texto não foi copiado integralmente.");
        }
        updateCharCount();
      });

      input.addEventListener("keydown", e => {
        const qtd = input.value.length;
        if (teclasPermitidas.includes(e.keyCode)) {
          return;
        }
        if (qtd >= limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!");
          e.preventDefault();
          return;
        }
      });
    });
  });
}

// Função para contar/limitar os caracteres do input
function input_limite() {
  const teclasPermitidas = [8, 9, 37, 38, 39, 40, 46, 17, 67, 86, 65];

  const inputs = document.querySelectorAll("input");
  inputs.forEach(input => {
    // Verificar se o elemento possui a classe "consulta"
    if (input.closest(".consulta")) {
      input.setAttribute("readonly", true);
      return; // Ignorar elementos com a classe "consulta"
    }
    // Verificar se o elemento possui a classe "contador"
    if (!input.closest(".contador")) {
      return; // Ignorar elementos sem a classe "contador"
    }

    const limite = parseInt(input.dataset.limite);

    input.setAttribute("maxlength", limite);

    const formGroup = input.closest(".contador");

    // Adiciona um ouvinte de evento para criar o contador quando o campo recebe o foco
    input.addEventListener("focus", () => {
      const labelSpan = document.createElement("span");
      labelSpan.classList.add("limite"); // Adiciona a classe "limite" ao span
      labelSpan.style.float = "right";

      // Adiciona o contador como irmão do input
      input.parentNode.insertBefore(labelSpan, input.nextSibling);

      function updateCharCount() {
        const qtd = input.value.length;
        labelSpan.innerHTML = `${qtd} / ${limite}`;
      }

      updateCharCount(); // Atualiza o contador quando o campo recebe o foco

      input.addEventListener("input", () => {
        updateCharCount();
      });

      // Adiciona um ouvinte de evento para ocultar o contador quando o campo perde o foco
      input.addEventListener("blur", () => {
        labelSpan.remove();
      });

      input.addEventListener("paste", e => {
        const qtd = e.clipboardData.getData("text/plain").length;
        if (qtd > limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!\nSeu texto não foi copiado integralmente.");
        }
        updateCharCount();
      });

      input.addEventListener("keydown", e => {
        const qtd = input.value.length;
        if (teclasPermitidas.includes(e.keyCode)) {
          return;
        }
        if (qtd >= limite) {
          toast.clear();
          toast.warning("Limite de caracteres alcançado!");
          e.preventDefault();
          return;
        }
      });
    });
  });
}

// Função para formartar o CPF ou CNPJ
function formatarDocumento(documento) {
  // Limpa todos os caracteres não numéricos
  documento = documento.replace(/\D/g, "");

  // Verifica se é CPF (11 dígitos) ou CNPJ (14 dígitos)
  if (documento.length === 11) {
    // Aplica a máscara de CPF
    documento = documento.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      "$1.$2.$3-$4"
    );
  } else if (documento.length === 14) {
    // Aplica a máscara de CNPJ
    documento = documento.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    // Caso contrário, retorna o documento sem formatação
    return documento;
  }

  return documento;
}

// Função para formatar a Data e Hora
const formatarDataHora = valor => {
  let dateObject;

  // Se valor é uma string, tenta converter para Date
  if (typeof valor === "string") {
    dateObject = new Date(valor);

    // Verifica se a conversão foi bem-sucedida
    if (!isNaN(dateObject.getTime())) {
      // Se foi bem-sucedida, formata a data
      return dateObject.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
      });
    }
  }

  // Se não for uma string ou a conversão falhar, retorna o valor original
  return valor;
};

// Função para formatar a Data e Hora para listagem do datatable
const formatarDataHoraListagem = value => {
  const date = new Date(value);
  return date
    .toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    })
    .replace(",", "");
};

// Função para formatar a Data
const formatarData = valor => {
  let dateObject;

  // Se valor é uma string, tenta converter para Date
  if (typeof valor === "string") {
    dateObject = new Date(valor);

    // Verifica se a conversão foi bem-sucedida
    if (!isNaN(dateObject.getTime())) {
      // Se foi bem-sucedida, formata a data
      return dateObject.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
      }).replace(',', '');
    }
  }

  // Se não for uma string ou a conversão falhar, retorna o valor original
  return valor;
};

// Função para criptografar o menu
function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

  return JSON.parse(jsonPayload);
}

// Função para remover o placeholder em modo consulta
function removePlaceholder(route) {
  if (route.query.consulta) {
    const element = document.querySelectorAll('[placeholder]');

    element.forEach(function (element) {
      // element.removeAttribute('placeholder');
      element.setAttribute('placeholder', 'Não informado');
    });
  }
}

// função para ir para as pendencias
function scrollPendencias(event, index) {
  document.querySelectorAll("a[data-scroll-to]").forEach(link => {
    link.addEventListener("click", clickEvent => {
      clickEvent.preventDefault();
      document.querySelector("#panel-pendencias").scrollIntoView({ behavior: 'smooth' });
    });
  });
}

// Função para formatar o CNPJ
const formatarCnpj = () => ({
  get: () => cnpj.value,
  set: newValue => {
    // Remove caracteres não numéricos
    const cleanCnpj = newValue.replace(/\D/g, "");

    // Verifica se o CNPJ tem o tamanho correto
    if (cleanCnpj.length === 14) {
      // Aplica a máscara (99.999.999/9999-99)
      cnpj.value = cleanCnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5"
      );

    } else {
      // CNPJ inválido, exibe mensagem de erro
      cnpj.value = newValue;

    }
  }
});

// Função para formatar Numero
const formatarNumero = function (valor) {
  // Verifica se o valor é numérico
  if (isNaN(valor)) {
    return "Valor inválido";
  }

  // Converte o valor para número
  valor = parseFloat(valor);

  // Formata o número para o formato brasileiro
  const numeroFormatado = new Intl.NumberFormat('pt-BR').format(valor);

  return numeroFormatado;
};

// Função para formatar Moeda
const formatarMoeda = function (valor) {
  // Verifica se o valor é null ou undefined
  if (valor === null || valor === undefined) {
    return "Valor inválido";
  }
  // Verifica se o valor é numérico
  if (isNaN(valor)) {
    return "Valor inválido";
  }

  // Converte o valor para número
  valor = parseFloat(valor);

  // Define as opções de formatação para duas ou quatro casas decimais
  let options = {
    style: 'currency',
    currency: 'BRL'
  };

  // Verifica as casas decimais do valor
  const valorString = valor.toFixed(4);
  const [inteiro, decimal] = valorString.split('.');

  if (decimal.slice(2) === "00") {
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;
  } else {
    options.minimumFractionDigits = 4;
    options.maximumFractionDigits = 4;
  }

  // Formata o número para o formato de moeda brasileira
  const numeroFormatado = new Intl.NumberFormat('pt-BR', options).format(valor);

  return numeroFormatado;
};

// Função para formatar Percentual
const formatarPercentual = function (valor) {
  // Verifica se o valor é null ou undefined
  if (valor === null || valor === undefined) {
    return "Valor inválido";
  }

  // Verifica se o valor é numérico
  if (isNaN(valor)) {
    return "Valor inválido";
  }

  // Converte o valor para número
  valor = parseFloat(valor);

  // Define as opções de formatação para percentual com duas casas decimais
  let options = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  // Formata o número para o formato de percentual
  const percentualFormatado = new Intl.NumberFormat('pt-BR', options).format(valor / 100);

  return percentualFormatado;
};

// Função para obter o dia
const getDia = function () {
  // Obtém a data atual
  const today = new Date();
  // Formata a data no formato aceito pelo input datetime-local (YYYY-MM-DDTHH:MM)
  const formattedToday = today.toISOString().slice(0, 16);
  return formattedToday;
}

// Função para não permitir exibir datas anterios a data atual no componente de calendário
const minDate = function () {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Função para converter em data os campos de data para listagem no datatable
const converteDate = function (data, fields) {
  return data.map(d => {
    fields.forEach(field => {
      if (d[field]) {
        d[field] = new Date(d[field]); // Certifique-se de que a data seja um objeto Date
      }
    });
    return d;
  });
};

// Função para selecionar o texto do input
const selecionarTexto = (valor) => {

  // Construa a classe dinâmica com base no valor
  const className = `.textoselecionado_${valor}`;
  const inputElement = document.querySelector(className + ' input');

  if (inputElement) {
    inputElement.select(); // Seleciona todo o texto no campo
  }
};

// Função para expandir os itens do Grupo
const expandirItens = (grupo, formaDisputa, arrayItens) => {

  if (formaDisputa === 1) {
    // Encontra o grupo selecionado
    const grupoSelecionado = arrayItens.find(
      g => g.idgrupo === grupo.idgrupo
    );

    if (grupoSelecionado) {
      // Verifica se existe algum item com collapsed igual a true
      const algumItemRecolhido = grupoSelecionado.itens.some(item => item.collapsed === true);

      if (!algumItemRecolhido) {
        toast.clear();
        toast.warning('Não existe item para expandir!');
        return;
      }

      // Atualiza o estado 'collapsed' de todos os itens do grupo selecionado
      grupoSelecionado.itens.forEach(item => {
        item.collapsed = false; // Expande os itens do grupo
      });
    }
  }
};


// Função para recolher os itens do Grupo
const recolherItens = (grupo, formaDisputa, arrayItens) => {
  if (formaDisputa === 1) {
    // Encontra o grupo selecionado
    const grupoSelecionado = arrayItens.find(
      g => g.idgrupo === grupo.idgrupo
    );

    if (grupoSelecionado) {
      // Verifica se existe algum item com collapsed igual a false
      const algumItemExpando = grupoSelecionado.itens.some(item => item.collapsed === false);

      if (!algumItemExpando) {
        toast.clear();
        toast.warning('Não existe item para recolher!');
        return;
      }

      // Atualiza o estado 'collapsed' de todos os itens do grupo selecionado
      grupoSelecionado.itens.forEach(item => {
        item.collapsed = true; // Recolhe os itens do grupo
      });
    }
  }
};


// Função para expandir os itens ou grupos Principais
const expandirPanelPrincipal = (formaDisputa, arrayGrupo, arrayItens) => {
  let existeItemRecolhido = false;

  if (formaDisputa === 1) {
    existeItemRecolhido = arrayGrupo.some(grupo => grupo.collapsed === true);
  } else if (formaDisputa === 0) {
    existeItemRecolhido = arrayItens.some(item => item.collapsed === true);
  }

  if (!existeItemRecolhido) {
    toast.clear();
    toast.warning('Não existe item ou grupo para expandir!');
    return;
  }

  if (formaDisputa === 1) {
    arrayGrupo.forEach(grupo => {
      grupo.collapsed = false; // Expande todos os Panels de grupos

    });

  } else if (formaDisputa === 0) {
    arrayItens.forEach(item => {
      item.collapsed = false; // Expande todos os Panels de itens
    });
  }
};


// // Função para recolher os itens ou grupos Principais
const recolherPanelPrincipal = (formaDisputa, arrayGrupo, arrayItens) => {
  let existeItemExpandido = false;

  if (formaDisputa === 1) {
    existeItemExpandido = arrayGrupo.some(grupo => grupo.collapsed === false);
  } else if (formaDisputa === 0) {
    existeItemExpandido = arrayItens.some(item => item.collapsed === false);
  }

  if (!existeItemExpandido) {
    toast.clear();
    toast.warning('Não existe item ou grupo para recolher!');
    return;
  }

  if (formaDisputa === 1) {
    arrayGrupo.forEach(grupo => {
      grupo.collapsed = true; // Recolhe todos os Panels de grupos
    });
  } else if (formaDisputa === 0) {
    arrayItens.forEach(item => {
      item.collapsed = true; // Recolhe todos os Panels de itens
    });
  }
};


// Atualiza o valor do item collapsed
const validaCollapsed = (item, novoEstadoCollapsed) => {
  item.collapsed = novoEstadoCollapsed;
};

// Função para calcular o valor total
const calcularValorTotal = (item, event) => {
  const value = event.value;
  item.vlUnitario = value;
  const total = value * item.qtd;
  item.vlTotal = total;
};

const calcularValorComDesconto = (item, event, formaDisputa, field) => {
  const value = event.value;
  let vlComDesconto = 0;

  if (formaDisputa === 0) {
    vlComDesconto = item.vlunitestimado - (item.vlunitestimado * (value / 100));
  } else {
    vlComDesconto = item.vltotalestimadogrupo - (item.vltotalestimadogrupo * (value / 100)); //Exibe o valor final com o desconto

  }

  if (vlComDesconto > 0) {
    item[field] = vlComDesconto;
  } else {
    item[field] = null;
  }

};

// Função para calcular o valor total
const calcularDesconto = (item, event, formaDisputa) => {
  const value = event.value;
  if (value === null) {
    item.valordesconto = null;
    item.valorfinaldesconto = null;
  } else {
    if (formaDisputa === 0) {
      if (value <= 100) {
        item.valorfinaldesconto = item.vlunitestimado - (item.vlunitestimado * (value / 100)); //Exibe o valor final com o desconto
        item.valordesconto = item.vlunitestimado * (value / 100); // exibe o desconto

      } else {
        item.valordesconto = null;
        item.valorfinaldesconto = null;
      }
    } else {
      if (value <= 100) {
        item.valorfinaldesconto = item.vltotalestimadogrupo - (item.vltotalestimadogrupo * (value / 100)); //Exibe o valor final com o desconto
        item.valordesconto = item.vltotalestimadogrupo * (value / 100); // exibe o desconto

      } else {
        item.valordesconto = null;
        item.valorfinaldesconto = null;
      }
    }
  }

};

const capturarDataHoraServidor = () => {
  // Captura a data e hora atuais do servidor
  var dataHoraServidor = new Date();

  // Formata a data e hora para o formato desejado
  var dataHoraFormatada = dataHoraServidor.toLocaleString();

  // Retorna a data e hora formatada
  return dataHoraFormatada;
};

// // //Função para checar a aba ativa
const verificaAbaAtiva = (arrayAbas) => {
  const abaAtivaSelecionada = [];

  arrayAbas.forEach(aba => {
    if (aba.statusaba === 1) {
      abaAtivaSelecionada.push({ id: aba.id, value: aba.value });
    }
  });
  return abaAtivaSelecionada[0].id;
};

// // //Função para checar a existe aba
const checaExistenciaAba = (arrayAbas) => {
  const abas = [];

  arrayAbas.forEach(aba => {
    if (aba.existesaba === 1) {
      abas.push({ id: aba.id, value: aba.value });
    }
  });
  return abas;

};

// Função de temporizador passando data inicio e fim
const temporizador = function (datainicio, datafim, callback) {
  const end = new Date(datafim).getTime();
  let timerInterval;

  function updateTimer() {
    const now = new Date().getTime();
    if (now >= end) {
      callback("Tempo Encerrado!", false);
      clearInterval(timerInterval);

      return;
    }

    let distance = end - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let timerString = '';
    if (days > 0) {
      timerString += `${days}d `;
    }
    if (hours > 0 || timerString !== '') {
      timerString += `${hours}h `;
    }
    if (minutes > 0 || timerString !== '') {
      timerString += `${minutes}m `;
    }
    timerString += `${seconds}s`;

    // Verificar se o tempo restante é menor que 300 segundos
    const isBlinking = distance < 300000; // 300000 ms = 300 s

    callback(timerString.trim(), isBlinking);
  }

  updateTimer();
  timerInterval = setInterval(updateTimer, 1000);
};

// Função genérica para aplicar animação em um campo específico
const aplicarAnimacao = (campoId, classeAnimacao, duracao = 2000) => {
  const elemento = document.getElementById(campoId);
  if (elemento) {
    elemento.classList.add(classeAnimacao);
    // Remove a classe após a animação
    setTimeout(() => {
      elemento.classList.remove(classeAnimacao);
    }, duracao);
  } else {
    console.warn(`Elemento com ID ${campoId} não encontrado.`);
  }
};

const verificarIntervaloMaiorDesconto = (event, item, updateValue, field, input) => {
  const intervalo = event.value;
  if (item.idcriterio === 2) {
    if (intervalo > 100) {
      toast.clear()
      toast.warning("O intervalo não pode ser maior que 100%");
      updateValue(item, field, input); // Chame a função para apagar o valor
    }
  } else if (item.idcriterio === 3)
    if (intervalo < -100 || intervalo > 100) {
      toast.clear()
      toast.warning("O intervalo não pode ser menor que -100 ou maior que 100%");
      updateValue(item, field, input); // Chame a função para apagar o valor
    }

};

const updateValue = (item, field, input) => {

  item[field] = null;
  nextTick(() => {
    item[field] = 0;

    // Adicione um breve atraso para garantir que o DOM esteja atualizado
    setTimeout(() => {
      // Acessa o elemento <span> referenciado pelo nome fornecido
      const spanElement = document.querySelector(`span[name="${input}"]`);

      if (spanElement) {
        // Encontra o <input> dentro do <span>
        const inputElement = spanElement.querySelector('input');
        if (inputElement) {
          inputElement.focus();
          inputElement.select(); // Seleciona todo o valor

        } else {
          console.error('Elemento <input> não encontrado dentro do <span>');
        }
      } else {
        console.error('Elemento <span> não encontrado com o nome:', input);
      }
    }, 100); // Ajuste o atraso conforme necessário
  });
};

export {
  textarea_limite,
  input_limite,
  parseJwt,
  removePlaceholder,
  formatarDataHora,
  formatarData,
  scrollPendencias,
  formatarDocumento,
  formatarCnpj,
  formatarNumero,
  formatarMoeda,
  getDia,
  temporizador,
  minDate,
  converteDate,
  selecionarTexto,
  formatarDataHoraListagem,
  expandirItens,
  recolherItens,
  expandirPanelPrincipal,
  recolherPanelPrincipal,
  validaCollapsed,
  calcularValorTotal,
  capturarDataHoraServidor,
  verificaAbaAtiva,
  checaExistenciaAba,
  aplicarAnimacao,
  verificarIntervaloMaiorDesconto,
  formatarPercentual,
  calcularDesconto,
  updateValue,
  calcularValorComDesconto

};