<template>
  <div id="sidebar-menu">
    <ul class="sidebar-links custom-scrollbar" id="myDIV"
          :style="[layoutobject.split(' ').includes('horizontal-wrapper')  ? layout.settings.layout_type=='rtl'? {'  -right': margin+'px'} : {'margin-left': margin+'px'} :  { margin : '0px'}]"
          >
            <li class="back-btn">
              <router-link to="/">
                <img
                  class="img-fluid"
                  src="../../assets/images/logo/logo-icon.png"
                  alt=""
              />
            </router-link>
              <div class="mobile-back text-end">
                <span>Back</span
                ><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
              </div>
            </li>
            <li class="sidebar-main-title">
              <div>
                <h6 class="lan-1">General</h6>
              </div>
            </li>
            <li class="sidebar-list">
              <router-link to="/dashboard" class="sidebar-link sidebar-title">
                <svg class="stroke-icon">
                  <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
                </svg>
                <svg class="fill-icon">
                  <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
                </svg>
                <span class="lan-3">Dashboard</span>
              </router-link>
            </li>

            
            <li class="sidebar-list" v-for="item in itensMenu" :key="item.id">
                <router-link class="sidebar-link sidebar-title" :class="{ 'active': item.active }"  :to="item.submenu && item.submenu.length === 0 ?  '/'+ item.rotamenu : '#'" @click="setNavActive(item, index)">
                  <svg class="stroke-icon">
                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${item.iconemenu}`"></use>
                    </svg>
                    <svg class="fill-icon">
                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${item.iconemenu}`"></use>
                    </svg>
                  <span class="lan-3">{{item.menu}}</span>
                  <div class="according-menu" v-if="item.submenu && item.submenu.length > 0">
                      <i class="fa fa-angle-right pull-right"></i>
                  </div>
                </router-link>
                <ul class="sidebar-submenu" :class="{ 'menu-open': item.active }" v-if="item.submenu && item.submenu.length > 0" :style="{ display: item.active ? '' : 'none' }">
                    <li v-for="(itemFilho, indexFilho) in item.submenu" :key="indexFilho">
                      <a class="lan-4" :class="{ 'active': itemFilho.active }" :href="itemFilho.subitemmenu == 0 ? '/' + itemFilho.rota : 'javascript:void(0)'" v-on:click="hidesecondmenu()" @click="setNavActive(itemFilho, index)">
                        <span>{{itemFilho.menu}}</span>
                        <i class="fa fa-angle-right pull-right mt-1" v-if="itemFilho.subitemmenu && itemFilho.subitemmenu.length > 0"></i>
                      </a>
                    
                      <ul class="nav-sub-childmenu submenu-content" v-if="itemFilho.subitemmenu"  :class="{ 'opensubchild': itemFilho.active }">
                        <li v-for="(itemNeto, indexNeto) in itemFilho.subitemmenu" :key="indexNeto">
                          <router-link :class="{ 'active': itemNeto.active }" :to="'/' +  itemNeto.rota"
                               v-on:click="hidesecondmenu()"
                              @click="setNavActive(itemNeto, index)">
                              {{ (itemNeto.menu) }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                </ul>
            </li>
          </ul>
        </div>
</template>
<script>
  import { mapState, useStore } from 'vuex';
  import { ref, onMounted } from 'vue'; // Trocado de reactive para ref
  import { layoutClasses } from '../../constants/layout';
  import apiMenu from '../../services/apiMenu';
  import { useToast } from "vue-toastification";
 

  const toast = useToast();
  const store = useStore();;
  
  export default {
    name: 'Navmenu',
    data() {
      return {
        layoutobj:{},
        itensMenu: []
      };
    },

    computed: {
      ...mapState({
        menuItems: state => state.menu.data,
        layout: state => state.layout.layout,
        sidebar: state => state.layout.sidebarType,
        activeoverlay: (state) => state.menu.activeoverlay,
        togglesidebar: (state) => state.menu.togglesidebar,
        width: (state) => state.menu.width,
        height: (state) => state.menu.height,
        margin: (state) => state.menu.margin,
        menuWidth: (state) => state.menu.menuWidth,
      }),
      layoutobject: {	
        get: function () {	
          return JSON.parse(JSON.stringify(layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout)))[this.layout.settings.layout];	
        },	
        set: function () {	
          this.layoutobj = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);	
          this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[this.layout.settings.layout];	
          return this.layoutobj;	
        }	
      }
    },
    watch: {
      width() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        window.addEventListener('scroll',this.handleScroll);
        this.handleScroll(); 
        if (window.innerWidth < 992) {
          const newlayout = JSON.parse(JSON.stringify(this.layoutobject).replace('horizontal-wrapper', 'compact-wrapper'));
          document.querySelector('.page-wrapper').className = 'page-wrapper ' + newlayout;
          this.$store.state.menu.margin = 0;
        } else {
          document.querySelector('.page-wrapper').className = 'page-wrapper ' + this.layoutobject;
        }

        if((window.innerWidth < 1199 && this.layout.settings.layout === 'Tokyo') || (window.innerWidth < 1199 && this.layout.settings.layout === 'Moscow') || (window.innerWidth < 1199 && this.layout.settings.layout === 'Rome')) {
          this.menuItems.filter(menuItem => {
            menuItem.active = false;
          });
        }
      } 
    },
    created() {
    
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      if (this.$store.state.menu.width < 991) {
        this.layout.settings.layout = 'Dubai';	
        this.margin = 0;
      }
      setTimeout(()=> {
        const elmnt = document.getElementById('myDIV');
        this.$store.state.menu.menuWidth = elmnt.offsetWidth;
        this.$store.state.menu.menuWidth > window.innerWidth  ? 
          (this.$store.state.menu.hideRightArrow = false,this.$store.state.menu.hideLeftArrowRTL = false) : 
          (this.$store.state.menu.hideRightArrow = false,this.$store.state.menu.hideLeftArrowRTL = true);
      }, 500);
      this.layoutobject = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);	
      this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[this.layout.settings.layout];
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    mounted() {   
      this.getMenu();      
      this.setupChannelMenuListener();
      this.menuItems.filter(items => {
        if (items.path === this.$route.path)
          this.$store.dispatch('menu/setActiveRoute', items);
        if (!items.children) return false;
        items.children.filter(subItems => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch('menu/setActiveRoute', subItems);
          if (!subItems.children) return false;
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch('menu/setActiveRoute', subSubItems);
          });
        });
      });
    },
    methods: {
      
      getMenu() {
        const self = this;
        apiMenu.getAll().then(response => {
          self.itensMenu = response.data;
          self.$store.state.menu.data = self.itensMenu;
          //console.log(self.$store.state.menu.data)
        }).catch(e => {
          console.log(e);
        });
      },
      getMenuAtualizar() {
         
        apiMenu.getAll().then(response => {
           
          const newItems = response.data;
           // Recrie completamente o array de itens
            self.itensMenu = [...newItems];
            // Atualize a store
            self.$store.state.menu.data = [...self.itensMenu];

          //console.log(self.$store.state.menu.data)
        }).catch(e => {
          console.log(e);
        });
      },     
      
      setupChannelMenuListener() {
        const self = this;
        window.Echo.channel('channelmenu').listen('ChannelMenu', data => {
          try {
          // const newItems = data.mensagem.original.itens;
           console.log("entroucanal")
           // Recrie completamente o array de itens
           // self.itensMenu = [...newItems];
            // Atualize a store
            //self.$store.state.menu.data = [...self.itensMenu]; // Crie uma nova referência para forçar a atualização reativa
            //  toast.info("Seu Perfil foi Atualizado.");
            self.getMenu();
            this.$store.dispatch('initializeStateFromApi');
          } catch (error) {
            console.error('Erro ao processar os dados do evento:', error);
          }
        });
      },
 
      handleScroll() {
        if(window.scrollY > 400){
          if(this.layoutobject.split(' ').pop() === 'material-type' || this.layoutobject.split(' ').pop() ==='advance-layout')
            document.querySelector('.sidebar-main').className = 'sidebar-main hovered';
        }else{
          if(document.getElementById('sidebar-main'))
            document.querySelector('.sidebar-main').className = 'sidebar-main';
        }
      },
      
      setNavActive(item) {
        this.$store.dispatch('menu/setNavActive', item);
        if(this.layoutobject.split(' ').includes('compact-sidebar') && window.innerWidth > 991) {
          if(this.menuItems.some(menuItem => menuItem.active === true)) {
            this.$store.state.menu.activeoverlay = true;
          } else {
            this.$store.state.menu.activeoverlay = false;
          }
        }
      },
      hidesecondmenu() {
        if(window.innerWidth < 991) {
          this.$store.state.menu.activeoverlay = false,
          this.$store.state.menu.togglesidebar = false;
          this.menuItems.filter(menuItem => {
            menuItem.active = false;
          });
        } else if(this.layoutobject.split(' ').includes('compact-sidebar')){
          this.$store.state.menu.activeoverlay = false,
          this.menuItems.filter(menuItem => {
            menuItem.active = false;
          });
        }
      },
      handleResize() {
        this.$store.state.menu.width = window.innerWidth - 450;
      },
    }
    
  };
</script>
