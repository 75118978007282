import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/assets/scss/app.scss'
import VueFeather from 'vue-feather';
//import { createI18n } from 'vue-i18n'
import en from './locales/en.json';
import pt from './locales/pt.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import VueApexCharts from "vue3-apexcharts";
import { defaultLocale, localeOptions } from './constants/config';
import Breadcrumbs from './components/bread_crumbs';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
// import ToastService from 'primevue/toastservice';
// import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import Tag from 'primevue/tag';
import Paginator from 'primevue/paginator';
import InlineMessage from 'primevue/inlinemessage';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import PickList from 'primevue/picklist';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import Panel from 'primevue/panel';
import InputMask from 'primevue/inputmask';
import Toast from "vue-toastification";
import DataView from 'primevue/dataview';
import AutoComplete from 'primevue/autocomplete';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Fieldset from 'primevue/fieldset';
import Divider from 'primevue/divider';
import Tooltip from 'primevue/tooltip';
import Calendar from 'primevue/calendar';
import Sidebar from 'primevue/sidebar';
import FloatLabel from 'primevue/floatlabel';
import Timeline from 'primevue/timeline';
import Checkbox from 'primevue/checkbox';
import SpeedDial from 'primevue/speeddial';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';

import "vue-toastification/dist/index.css";
import 'primeicons/primeicons.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/themes/mira/theme.css";

//import "primevue/resources/themes/fluent-light/theme.css";
//import "primevue/resources/themes/mdc-light-indigo/theme.css";
//import "primevue/resources/themes/mdc-light-deeppurple/theme.css";

import "primeflex/primeflex.css";


import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    authEndpoint: 'http://10.3.14.230:8081/broadcasting/auth',
    broadcaster: 'pusher',
    key: '653c743da10dc93e50e5',
    cluster: 'sa1',
    wsPort: 6001,
    forceTLS: false,
    disabledStats: true
});

const messages = { en: en, es: es, pt: pt, fr: fr };
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;
// Chama a ação de inicialização ao recarregar
store.dispatch('initializeStateFromApi');



// Desativa competenciasUsuario no devTools do vuex
// if (process.env.NODE_ENV === 'development') {
//     console.log('DevTools do Vuex está sendo desativado no ambiente de desenvolvimento');
//     store.replaceState({
//       ...store.state,
//       competenciasUsuario: ['Dados criptografados para proteção'],
//     });
//   }
createApp(App)
    .use(router)
    .use(store)
    .use(PrimeVue, {
        locale: pt // Use a configuração de localidade correta aqui
    })
    .use(ConfirmationService)
    .use(Toast)
    .use(DialogService)
    .component('Textarea', Textarea)
    .component('Tree', Tree)
    .component('TreeSelect', TreeSelect)
    .component('TreeTable', TreeTable)
    .component('Dialog', Dialog)
    .component('Toolbar', Toolbar)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('InputMask', InputMask)
    .component('DataTable', DataTable)
    .component('DataView', DataView)
    .component('InputNumber', InputNumber)
    .component('Dropdown', Dropdown)
    .component('Paginator', Paginator)
    .component('RadioButton', RadioButton)
    .component('ConfirmPopup', ConfirmPopup)
    .component('Tag', Tag)
    .component('InlineMessage', InlineMessage)
    .component('Column', Column)
    .component(VueFeather.name, VueFeather)
    .component('Breadcrumbs', Breadcrumbs)
    .component("apexchart", VueApexCharts)
    .component('PickList', PickList)
    .component('ConfirmDialog', ConfirmDialog)
    .component('Panel', Panel)
    .component('AutoComplete', AutoComplete)
    .component('InputGroup', InputGroup)
    .component('InputGroupAddon', InputGroupAddon)
    .component('Fieldset', Fieldset)
    .component('Divider', Divider)
    .component('Calendar', Calendar)
    .component('Sidebar', Sidebar)
    .component('FloatLabel', FloatLabel)
    .component('Timeline', Timeline)
    .component('Checkbox', Checkbox)
    .component('SpeedDial', SpeedDial)
    .component('Menu', Menu)
    .component('Menubar', Menubar)
    .directive('tooltip', Tooltip)
    .mount('#app')
