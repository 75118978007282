import { createRouter, createWebHistory } from "vue-router"
import axios from "axios";
import Body from '../components/body';
import Default from '../pages/dashboard/defaultPage.vue';
//import Default from '../pages/dashboard/WelcomeCard.vue';
import Treepage from '../pages/advance/tree/treeView.vue';
import login from '../pages/authentication/login_one.vue';
import forgetpassword from '../pages/authentication/forget_password.vue';
import resetpassword from '../pages/authentication/reset_password.vue';
//import General from '../pages/widgets/general'


const routes = [
    {
        path: '/dashboard',
        component: Body,

        children: [
            {
                path: '',
                name: 'defaultRoot',
                component: Default,
            },
            {
                path: '/Treepage',
                name: 'Treepage',
                component: Treepage,
            },
            {
                path: "/icones",
                name: "icones",
                component: () => import("../components/templates/icones-caixa-entrada.vue"),
                meta: {
                    title: ' Listagem de icones',
                },
            },
            {
                path: "/gestao/cadastros/orgaos",
                name: "orgao",
                component: () => import("../components/cadastros/orgaos/index.vue"),
                meta: {
                    title: ' Listagem de Orgaos',
                },
            },
            {
                path: "/gestao/cadastros/orgaos/:id",
                name: "orgao-edita",
                component: () => import("../components/cadastros/orgaos/form.vue")
            },
            {
                path: "/gestao/cadastros/orgaos/novo",
                name: "orgao-novo",
                component: () => import("../components/cadastros/orgaos/form.vue")
            },
            {
                path: "/gestao/cadastros/grupo-trabalho",
                name: "grupo-trabalho",
                component: () => import("../components/cadastros/grupo-trabalho/index.vue"),
                meta: {
                    title: ' Listagem de Grupos de Trabalho',
                },
            },
            {
                path: "/gestao/cadastros/grupo-trabalho/:id",
                name: "grupo-trabalho-form",
                component: () => import("../components/cadastros/grupo-trabalho/form.vue")
            },
            {
                path: "/gestao/cadastros/grupo-trabalho/novo",
                name: "grupo-trabalho-novo",
                component: () => import("../components/cadastros/grupo-trabalho/form.vue")
            },
            {
                path: "/gestao/cadastros/unidade-administrativa",
                name: "unidade-administrativa",
                component: () => import("../components/cadastros/unidade-administrativa/index.vue"),
                meta: {
                    title: ' Listagem de Grupos de Trabalho',
                },
            },
            {
                path: "/gestao/cadastros/unidade-administrativa/:id",
                name: "unidade-administrativa-form",
                component: () => import("../components/cadastros/unidade-administrativa/form.vue")
            },
            {
                path: "/gestao/cadastros/unidade-administrativa/novo",
                name: "unidade-administrativa-novo",
                component: () => import("../components/cadastros/unidade-administrativa/form.vue")
            },
            {
                path: "/gestao/cadastros/perfis",
                name: "perfis",
                component: () => import("../components/cadastros/perfis/index.vue"),
                meta: {
                    title: ' Listagem de perfis',
                },
            },
            {
                path: "/gestao/cadastros/perfis/:id",
                name: "perfis-form",
                component: () => import("../components/cadastros/perfis/form.vue")
            },
            {
                path: "/gestao/cadastros/perfis/novo",
                name: "perfis-novo",
                component: () => import("../components/cadastros/perfis/form.vue")
            },
            {
                path: "/gestao/cadastros/usuarios",
                name: "usuarios",
                component: () => import("../components/cadastros/usuarios/index.vue"),
                meta: {
                    title: ' Listagem de usuarios',
                },
            },
            {
                path: "/gestao/cadastros/usuarios/:id",
                name: "usuarios-form",
                component: () => import("../components/cadastros/usuarios/form.vue")
            },
            {
                path: "/gestao/cadastros/usuarios/novo",
                name: "usuarios-novo",
                component: () => import("../components/cadastros/usuarios/form.vue")
            },
            {
                path: "/processos",
                name: "processo",
                component: () => import("../components/processo/index.vue"),
                meta: {
                    title: ' Listagem de processo',
                },
            },
            {
                path: "/processos/:id",
                name: "processo-form",
                component: () => import("../components/processo/form.vue")
            },
            {
                path: "/processos/novo",
                name: "processo-novo",
                component: () => import("../components/processo/form.vue")
            },
            {
                path: "/pesquisa/:id",
                name: "pesquisa-forn",
                component: () => import("../components/pesquisa/form_pesquisa.vue")
            },
            // pesquisa fornecedor
            {
                path: "/pesquisas",
                name: "pesquisa",
                component: () => import("../components/fornecedor/pesquisa/index_pesquisa.vue"),
                meta: {
                    title: ' Listagem de pesquisa',
                },
            },

            {
                path: "/pesquisas/:idprocessos/:id",
                name: "pesquisa-form",
                component: () => import("../components/fornecedor/pesquisa/cotacao_pesquisa.vue")
            },
            ////////
            // Dispensa fornecedor
            // Lista todas as dispensas 
            {
                path: "/dispensas",
                name: "dispensas",
                component: () => import("../components/fornecedor/dispensa/index_dispensa.vue"),
                meta: {
                    title: ' Listagem dispensa',
                },
            },
            // Lista todas as dipensas em Inscrição de Propostas
            {
                path: "/dispensas/proposta",
                name: "dispensas-propostas",
                component: () => import("../components/fornecedor/dispensa/index_dispensa_proposta.vue"),
                meta: {
                    title: ' Listagem dispensa',
                },
            },
            // Lista a dipensa que está em Inscrição de Propostas

            {
                path: "/dispensas/proposta/:iddispensa/:id",
                name: "dispensas-propostas-form",
                component: () => import("../components/fornecedor/proposta/proposta_dispensa.vue")
            },

            // Lista todas as dipensas em Disputa
            {
                path: "/dispensas/disputa",
                name: "dispensas-disputa",
                component: () => import("../components/fornecedor/dispensa/index_dispensa_disputa.vue"),
                meta: {
                    title: ' Listagem dispensa',
                },
            },
            // Lista a dipensa que está em Disputa

            {
                path: "/dispensas/disputa/:iddispensa/:id",
                name: "dispensas-disputa-form",
                component: () => import("../components/fornecedor/dispensa/disputa_dispensa.vue")
            },

            {
                path: "/proposta-dispensa/:idprocessos/:id",
                name: "proposta-dispensa",
                component: () => import("../components/fornecedor/proposta/proposta_dispensa.vue")
            },
            ////////
            {
                path: "/dispensa/:id",
                name: "dispensa-form",
                component: () => import("../components/dispensa_eletronica/form_dispensa.vue")
            },
            {
                path: "/acompanhar-disputa/:id",
                name: "acompanhar-disputa",
                component: () => import("../components/dispensa_eletronica/acompanhar_disputa.vue")
            },
            {
                path: "/julgamento-propostas/:id",
                name: "julgamento-propostas",
                component: () => import("../components/dispensa_eletronica/julgamento_propostas.vue")
            },
            {
                path: "/recurso-dispensa/:id",
                name: "recurso-dispensa",
                component: () => import("../components/templates/recurso.vue")
            },
            {
                path: "/planejamento/:id",
                name: "planejamento-form",
                component: () => import("../components/planejamento/form_planejamento.vue")
            },
            {
                path: "/catalogo/itens",
                name: "catalogo-itens",
                component: () => import("../components/catalogo/index.vue"),
                meta: {
                    title: ' Listagem de catalogo',
                },
            },
            {
                path: "/catalogo/minhas-solicitacoes",
                name: "catalogo-minhasSolicitacoes",
                component: () => import("../components/catalogo/minhasSolicitacoes.vue")
            },
            {
                path: "/catalogo/minhas-solicitacoes/:id",
                name: "catalogo-form",
                component: () => import("../components/catalogo/form.vue")
            },
            {
                path: "/catalogo/minhas-solicitacoes/novo",
                name: "catalogo-novo",
                component: () => import("../components/catalogo/form.vue")
            },
            {
                path: "/gestao/cadastros/catalogacao",
                name: "catalogacao",
                component: () => import("../components/catalogacao/index.vue"),
                meta: {
                    title: ' Listagem de catalogacao',
                },
            },
            {
                path: "/gestao/cadastros/catalogacao/:id",
                name: "catalogacao-form",
                component: () => import("../components/catalogacao/form.vue")
            },
            {
                path: "/gestao/cadastros/catalogacao/novo",
                name: "catalogacao-novo",
                component: () => import("../components/catalogacao/form.vue")
            },
            // Licitação
            {
                path: "/licitacoes",
                name: "licitacao",
                component: () => import("../components/licitacao/index.vue"),
                meta: {
                    title: ' Listagem de licitacao',
                },
            },
            {
                path: "/licitacoes/:id",
                name: "licitacao-form",
                component: () => import("../components/licitacao/form.vue")
            },
            {
                path: "/licitacoes/novo",
                name: "licitacao-novo",
                component: () => import("../components/licitacao/form.vue")
            },

        ]
    },
    {
        path: '/login',
        name: 'login',
        component: login,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: resetpassword,
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        component: forgetpassword,
    }




]
const router = createRouter({
    history: createWebHistory(),
    routes
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 422) {
            // Token expirado, redirecione para a página de login
            localStorage.setItem('logout_message', 'error:Sua sessão expirou. Faça login novamente.');
            router.push('/login');
        }
        return Promise.reject(error);
    }
);

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('vemlicitar_token');

    if (to.path !== '/login' && !token) {
        localStorage.setItem('logout_message', 'error:Você não está autenticado no sistema.')
        next('/login');
    } else {
        next();
    }
});

export default router

